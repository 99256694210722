/* General */

.highlight {
  color: rgb(50, 190, 160);
}

.card {
  background-color: #ffffff06;
}

.gray {
  color: #bbbbbb;
}

.corner-image {
  position: absolute;
  top: 16px;
  right: 28px;
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
  border-radius: 0.375rem;
}

.corner-image-card-header {
  margin-right: 4rem;
}

/* Navbar */

.navbar {
  height: 3.5rem;
  display: flex;
  justify-content: right;
  padding: 0rem 2rem;
  gap: 2rem;
}

.navbar a {
  text-decoration: none;
  color: #ffffff;
}

.navbar a:hover {
  color: #bbbbbb;
}

/* Header */

.header {
  min-height: calc(100vh - 3.5rem);
}

.name {
  font-size: 3rem;
}

.profile-picture {
  box-shadow: 3px 3px 10px 2px rgba(100, 100, 100, 0.5);
  max-width: 25rem;
  display: block;
  width: auto;
  height: auto;
}

/* Social Buttons */

.social-buttons a {
  color: #ffffff;
  background-color: rgb(50, 190, 160);
  border-color: rgb(50, 190, 160);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  transition: transform 250ms;
  display: inline-block;
  text-align: center;
  line-height: 4rem;
}

.last-social-button {
  margin-right: 0 !important;
}

.social-buttons a:hover, 
.social-buttons a:active {
  transform: scale(1.1);
  color:#ffffff;
}

/* Education */

.school-card {
  position: relative;
}

.activities {
  justify-content: center;
  row-gap: 0.5rem;
}

.activities div {
  text-align: center;
}

.activities img {
  width: 3rem;
  border-radius: 0.375rem;
}

.activities a {
  text-decoration: none;
}

/* Skills */

.skills-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  justify-content: center;
}

.skill-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Experience */

.experience-card {
  position: relative;
}

/* Projects */

.clickable {
  cursor: pointer;
  transition-duration: 300ms;
}

.clickable:hover {
  transform: scale(1.025);
}

.project-card img {
  height: 14rem;
  width: 100%;
  object-fit: cover;
  border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0;
}

/* Contact */

.form-item,
.form-item:hover {
  background-color: #ffffff09;
  border-color: #ffffff09;
  color: #ffffff;
}

.form-item:focus,
.form-item:active {
  background-color: #ffffff11;
  border-color: #ffffff11;
  color: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000rem rgba(1, 1, 1, 0.8) inset !important;
  -webkit-text-fill-color: #ffffff;
}

.button,
.button:hover {
  background-color: rgb(50, 190, 160);
  border-color: rgb(50, 190, 160);
}

.button:active {
  background-color: rgb(35, 160, 130) !important;
  border-color: rgb(35, 160, 130) !important;
}