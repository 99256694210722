@import 'https://fonts.googleapis.com/css?family=Open+Sans';

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5em;
	font-size: 16px;
	background-color: #202022;
	color: #aaa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark-bg {
    background-color: #262628;
}

.darker-bg {
    background-color: #1d1d20;
}

.simple-container {
	max-width: 675px;
	margin: 0 auto;
	padding-top: 70px;
	padding-bottom: 20px;
}

.simple-print {
	fill: white;
	stroke: white;
}

.simple-print svg {
	height: 100%;
}

.simple-close {
	color: white;
	border-color: white;
}

.simple-ext-info {
	border-top: 1px solid #aaa;
}

p {
	font-size: 16px;
    line-height: 20px;
    margin: 0.5rem;
}

h1 {
	font-size: 46px;
	line-height: 50px;
}

h2 {
	font-size: 30px;
	line-height: 34px;
}

h3 {
	font-size: 22px;
	line-height: 25px;
}

h4 {
    font-size: 18px;
    line-height: 20px;
}

hr {
	height: 1px;
	background-color: #d8d8d8;
	border: none;
	width: 100%;
	margin: 0px;
}

a[href] {
	color: #1e8ad6;
}

a[href]:hover {
	color: #3ba0e6;
}

img {
	max-width: 100%;
}

li {
	line-height: 1.5em;
}

aside,
[class *= "sidebar"],
[id *= "sidebar"] {
	max-width: 90%;
	margin: 0 auto;
	border: 1px solid lightgrey;
	padding: 5px 15px;
}

@media (min-width: 1921px) {
	body {
		font-size: 18px;
	}
}